import React, { useCallback, useEffect, useRef, useState } from "react";

import { sanitizeNumber } from "@/util/helpers.mjs";

const MOUSE_DELTA = 6;

function DraggableVideo({ src, poster }: { src: string; poster?: string }) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [timeoutId, setTimeoutId] = useState<Timeout | null>(null);
  const [startX, setStartX] = useState(-1);
  const [seeked, setSeeked] = useState(false);

  const seekVideo = useCallback((moveX: number) => {
    if (!videoRef.current) return;
    const duration = videoRef.current.duration;
    const videoWidth = videoRef.current.clientWidth;
    videoRef.current.currentTime = sanitizeNumber(
      videoRef.current.currentTime + (duration * moveX) / videoWidth,
    );
  }, []);

  const onMouseDown = useCallback(
    (
      event:
        | React.MouseEvent<HTMLVideoElement>
        | React.TouchEvent<HTMLVideoElement>,
    ) => {
      const pageX =
        event.nativeEvent instanceof MouseEvent
          ? (event as React.MouseEvent).pageX
          : (event as React.TouchEvent).touches[0].pageX;
      setStartX(pageX);
    },
    [],
  );

  const onMouseUp = useCallback(() => {
    setStartX(-1);
    if (seeked) {
      const timeoutId = setTimeout(() => {
        if (videoRef.current?.paused) {
          videoRef.current.play();
        }
        setTimeoutId(null);
      }, 2000);
      setTimeoutId(timeoutId);
    }
  }, [seeked]);

  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  const onMouseMove = useCallback(
    (
      event:
        | React.MouseEvent<HTMLVideoElement>
        | React.TouchEvent<HTMLVideoElement>,
    ) => {
      const pageX =
        event.nativeEvent instanceof MouseEvent
          ? (event as React.MouseEvent).pageX
          : (event as React.TouchEvent).touches[0].pageX;
      const diffX = Math.abs(pageX - startX);
      if (startX > -1 && diffX > MOUSE_DELTA) {
        setStartX(pageX);
        seekVideo(pageX - startX);
        if (!seeked) videoRef.current.pause();
        setSeeked(true);
      }
    },
    [seeked, seekVideo, startX],
  );

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLVideoElement>) => {
      if (seeked) {
        setSeeked(false);
        return;
      }
      const target = event.target as HTMLVideoElement;
      if ((target as HTMLVideoElement).paused) {
        target.play();
      } else {
        target.pause();
      }
    },
    [seeked],
  );
  const onLoadStart = useCallback(() => {
    if (videoRef.current) videoRef.current.volume = 0.5;
  }, []);

  return (
    <video
      ref={videoRef}
      src={src}
      poster={poster}
      loop
      autoPlay
      onTouchStart={onMouseDown}
      onTouchMove={onMouseMove}
      onTouchEnd={onMouseUp}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      onClick={onClick}
      controls={false}
      playsInline
      onLoadStart={onLoadStart}
    />
  );
}

export default DraggableVideo;
