import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { css } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP, IS_TESTING } from "@/__main__/constants.mjs";
import type { Meta } from "@/__main__/router.mjs";
import router from "@/__main__/router.mjs";
import { setVolatileKV } from "@/app/actions.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { APP_SCROLLER, hardCodeURLs } from "@/app/constants.mjs";
import DownloadButtonWrapper from "@/app/DownloadButtonWrapper.jsx";
import DraggableVideo from "@/game-fortnite/components/DraggableVideo.jsx";
import Price from "@/game-fortnite/components/ItemPrice.jsx";
import Reactions from "@/game-fortnite/components/Reactions.jsx";
import { EVENT_FORTNITE_ITEM_SHARE } from "@/game-fortnite/constants/events.mjs";
import Rarities from "@/game-fortnite/constants/rarities.mjs";
import { GAME_SYMBOL_FORTNITE } from "@/game-fortnite/definition.mjs";
import type {
  Item,
  ItemOption,
  ItemShopEntry,
} from "@/game-fortnite/models/item-shop.mjs";
import {
  offerIdToPathParam,
  pathParamToOfferId,
} from "@/game-fortnite/utils/transform-offerid.mjs";
import ChevronLeft from "@/inline-assets/chevron-left.svg";
import EpicLogoIcon from "@/inline-assets/epic-logo.svg";
import ShareIcon from "@/inline-assets/share.svg";
import { WikiLayout, WikiSidebar } from "@/shared/Wiki.jsx";
import { classNames } from "@/util/class-names.mjs";
import { devLog } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";
import { formatDateTime, getLocale } from "@/util/i18n-helper.mjs";
import isMobile from "@/util/is-mobile.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import setImageSizeInURL from "@/util/set-image-size-in-url.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ENTRIES_LIMIT = 6;

const ItemImageCss = (bgColor1: string, bgColor2: string) => css`
  width: 100%;
  height: calc(16.8 * var(--sp-10));
  border-radius: var(--br);
  background: radial-gradient(#${bgColor2}, #${bgColor1});
  img {
    height: 100%;
    width: auto;
    margin: auto;
  }
  @container (inline-size <= 700px) {
    width: 100%;
    height: auto;
    img {
      width: 100%;
      height: auto;
    }
  }
`;

const ItemVideoCss = () => css`
  display: flex;
  video {
    width: 100%;
    height: auto;
    border-radius: var(--br);
    margin: auto;
  }
`;

const ItemEntryCss = (bgColor1: string, bgColor2: string) => css`
  width: var(--sp-16);
  height: var(--sp-16);
  border-radius: var(--br);
  background: radial-gradient(#${bgColor2}, #${bgColor1});
  outline: solid 2px transparent;
  outline-offset: var(--sp-0_5);
  cursor: pointer;
  img {
    height: 100%;
    width: auto;
    margin: auto;
  }
  &:hover {
    outline-color: var(--shade0-25);
  }
  &[data-is-selected="true"] {
    outline-color: var(--shade0-25);
  }
`;

const ShopEntryCss = (bgColor1: string, bgColor2: string) => css`
  text-decoration: none;
  .img-container {
    width: calc(var(--sp-10) * 5);
    height: calc(var(--sp-10) * 3.9);
    border-radius: var(--br-lg);
    background: radial-gradient(#${bgColor2}, #${bgColor1});
    outline: solid 2px transparent;
    outline-offset: var(--sp-0_5);
    cursor: pointer;
    img {
      height: 100%;
      width: auto;
      margin: auto;
    }
    &:hover {
      outline-color: var(--shade0-25);
    }
  }
  p {
    max-width: 20ch;
  }
`;

const ItemEntriesCss = () => css`
  margin-top: var(--sp-4);
  overflow: auto;
  p.entry-title {
    font-size: var(--sp-4);
    font-weight: 750;
  }
`;

const WikiContainerCSS = () => css`
  gap: initial !important;
  .content > .sidebar {
    padding-left: var(--sp-2);
  }
  .main {
    padding-right: var(--sp-2);
  }
  .reactions {
    margin: 0 auto;
  }
  .page-header .container {
    flex-grow: 1;
    & > div:nth-child(2) {
      flex-grow: 1;
    }
  }
`;

const ItemVariantCSS = () => css`
  .header {
    text-transform: capitalize;
  }
  .variant-option {
    width: var(--sp-8);
    height: var(--sp-8);
    border-radius: var(--br-sm);
    outline: solid 1px transparent;
    outline-offset: var(--sp-0_5);
    cursor: pointer;
    img {
      height: 100%;
      width: auto;
      margin: auto;
    }
    &:hover {
      outline-color: var(--shade0-25);
    }
    &[data-is-selected="true"] {
      outline-color: var(--shade0-25);
    }
  }
`;

const BackToShopCSS = () => css`
  display: flex;
  align-items: center;
  gap: var(--sp-1);
  a {
    text-decoration: none;
    transition: var(--transition);
    color: inherit;
  }
  .icon {
    svg {
      width: var(--sp-3);
      height: var(--sp-3);
    }
  }
  color: var(--shade2);
  &:hover {
    color: var(--shade0);
  }
`;

function ItemEntry({
  entry,
  item,
  selectItem,
  isSelected,
}: {
  entry: ItemShopEntry;
  item: Item;
  selectItem: () => void;
  isSelected: boolean;
}) {
  const renderImage = entry.newDisplayAsset?.renderImages?.[0];
  const imageUrl = item.images.featured || item.images.icon;
  if (!renderImage || !imageUrl) return null;
  return (
    <div
      className={ItemEntryCss(
        entry?.colors?.color1 || "",
        entry?.colors?.color2 || "",
      )}
      onClick={selectItem}
      data-is-selected={isSelected}
    >
      <img
        width={64}
        height={64}
        src={setImageSizeInURL(imageUrl, 64)}
        alt={item.name}
        loading="lazy"
      />
    </div>
  );
}

function ItemVariant({
  option,
  selectVariant,
  isSelected,
}: {
  option: ItemOption;
  selectVariant: () => void;
  isSelected: boolean;
}) {
  return (
    <div
      className="variant-option"
      onClick={selectVariant}
      data-is-selected={isSelected}
    >
      <img
        width={40}
        height={40}
        src={setImageSizeInURL(option.image, 40)}
        alt={option.name}
        loading="lazy"
      />
    </div>
  );
}

function ShopEntryLink({ entry }: { entry: ItemShopEntry }) {
  const renderImage = entry.newDisplayAsset?.renderImages?.[0];
  if (!renderImage) return null;

  return (
    <a
      href={`/fortnite/shop/${offerIdToPathParam(entry.offerId)}`}
      className={ShopEntryCss(
        entry?.colors?.color1 || "",
        entry?.colors?.color2 || "",
      )}
    >
      <div className="img-container">
        <img
          width={64}
          height={64}
          src={setImageSizeInURL(renderImage.image, 156)}
          loading="lazy"
        />
      </div>
      <p className="type-title--bold item-title">
        {entry.bundle?.name || entry.brItems?.[0]?.name}
      </p>
    </a>
  );
}

function Entries({ entries }: { entries: ItemShopEntry[] }) {
  const { t } = useTranslation();
  return (
    <Card
      as="div"
      className={classNames("flex column gap-5", ItemEntriesCss()).className}
    >
      <p className="type-title--bold entry-title">
        {t("fortnite:otherItems", "Other items in the shop")}
      </p>
      <div className="flex row gap-4">
        {entries.map((entry) => {
          return (
            <ShopEntryLink key={entry.offerId} entry={entry as ItemShopEntry} />
          );
        })}
      </div>
    </Card>
  );
}

function BundleInfoSidebar({
  entry,
  selectItem,
  selectedItem,
}: {
  entry: ItemShopEntry;
  selectItem: (index: number) => void;
  selectedItem: number;
}) {
  const { t } = useTranslation();
  return (
    <Card as="div" padding={0} className="flex column sidebar-card">
      <WikiSidebar.Section>
        <WikiSidebar.Header>
          {t("fortnite:whatsIncluded", "What's included?")}
        </WikiSidebar.Header>
        <div className="flex row wrap gap-2">
          {entry.brItems?.map((item, index) => {
            return (
              <ItemEntry
                key={item.id}
                entry={entry as ItemShopEntry}
                item={item as Item}
                selectItem={() => selectItem(index)}
                isSelected={selectedItem === index}
              />
            );
          })}
        </div>
      </WikiSidebar.Section>
    </Card>
  );
}

function ItemVariantsSidebar({
  item,
  selectVariant,
  selectedVariant,
}: {
  item: Item;
  selectedVariant: Record<number, number>;
  selectVariant: (vIndex: number, oIndex: number) => void;
}) {
  const { t } = useTranslation();
  if (!item.variants?.length) return null;
  return (
    <Card
      as="div"
      padding={0}
      className={
        classNames("flex column sidebar-card", ItemVariantCSS()).className
      }
    >
      <WikiSidebar.Section>
        {item.variants.map((variant, vIndex) => {
          const optionIndex = selectedVariant[vIndex]
            ? selectedVariant[vIndex]
            : 0;
          return (
            <div key={`variant-${vIndex}`} className="flex column">
              <WikiSidebar.Header className="header">
                {variant.type || t("fortnite:variantType", "Type")} (
                {variant.options[optionIndex].name})
              </WikiSidebar.Header>
              <div className="flex row wrap gap-2">
                {variant.options.map((option, oIndex) => {
                  return (
                    <ItemVariant
                      key={option.tag}
                      option={option}
                      selectVariant={() => selectVariant(vIndex, oIndex)}
                      isSelected={optionIndex === oIndex}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </WikiSidebar.Section>
    </Card>
  );
}

function ItemInfoSidebar({ item }: { item: Item }) {
  const { t } = useTranslation();

  const raritySymbol = Object.getOwnPropertySymbols(Rarities).find(
    (s) => Rarities[s].gameValue === item.rarity.backendValue,
  );
  const rarity = raritySymbol ? Rarities[raritySymbol] : undefined;

  return (
    <Card as="div" padding={0} className="flex column sidebar-card">
      <WikiSidebar.Section>
        <WikiSidebar.Header>
          {t("fortnite:summary", "Summary")}
        </WikiSidebar.Header>
        <dl>
          <dt>{t("fortnite:name", "Name")}</dt>
          <dd>{item.name}</dd>
          <dt>{t("fortnite:rarity", "Rarity")}</dt>
          <dd style={{ color: rarity ? `hsla(${rarity.color}, 1)` : "" }}>
            {rarity ? t(...rarity.t) : item.rarity.displayValue}
          </dd>
          <dt>{t("fortnite:item.category", "Category")}</dt>
          <dd>{item.type.displayValue}</dd>
          {item.added ? (
            <>
              <dt>{t("fortnite:item.addedOn", "Added On")}</dt>
              <dd>{formatDateTime(getLocale(), new Date(item.added))}</dd>
            </>
          ) : null}
          <dt>{t("fortnite:item.occurances", "Occurances")}</dt>
          <dd>{item.shopHistory?.length ?? 0}</dd>
        </dl>
        <Reactions id={item.id} className="reactions" />
      </WikiSidebar.Section>
    </Card>
  );
}

function ItemActionsSidebar() {
  const { t } = useTranslation();

  if (IS_APP) return null;

  return (
    <div className="flex column gap-2 sidebar-card">
      <h6 className="type-title--semi">
        {t(
          "welcome:fortniteHeadline",
          "Win up to 30% more games with Blitz’s Fortnite Performance Tracker",
        )}
      </h6>
      <p className="flex column gap-2 wrap type-page-subheader subheader">
        <span className="flex gap-1 wrap">
          <Trans i18nKey="welcome:header.subtitle">
            The Blitz app is trusted by <strong>4,300,000+</strong> users daily.
          </Trans>
          <Trans i18nKey="welcome:header.publisherComp">
            <strong className="flex gap-1 align-center">
              <EpicLogoIcon width={24} height={24} />
              Epic Games
            </strong>{" "}
            compliant.
          </Trans>
        </span>
      </p>
      {isMobile() ? (
        <Button
          as="a"
          className="w-full"
          href="/welcome/fortnite"
          emphasis="high"
          target="_blank"
        >
          {t("common:learnMore", "Learn More")}
        </Button>
      ) : (
        <DownloadButtonWrapper target="_blank" className="w-full">
          {t("common:downloadBlitz", "Download Blitz")}
        </DownloadButtonWrapper>
      )}
    </div>
  );
}

function BackToShop() {
  const { t } = useTranslation();

  const params =
    router.previousRoute?.path === "/fortnite/shop"
      ? router.previousRoute?.searchParams
      : undefined;
  const href = !params
    ? "/fortnite/shop"
    : `/fortnite/shop?${params.toString()}`;

  return (
    <div className={BackToShopCSS()}>
      <div className="icon">
        <ChevronLeft />
      </div>
      <a href={href} className="type-callout--semi">
        {t("fortnite:itemShop", "Item Shop")}
      </a>
    </div>
  );
}

function ShareButton() {
  const { t } = useTranslation();
  const { currentPath, metadata } = useRoute();

  const copyLink = useCallback(async () => {
    const link = `${globals.location.origin}${currentPath}`;
    try {
      await globals.navigator.share({
        title: metadata ? t(...metadata.title) : "Blitz",
        text: metadata
          ? t(...metadata.description)
          : "View amazing contents of this link",
        url: link,
      });
    } catch (e) {
      devLog("Error triggering share dialog. Copying link to clipboard", e);
      globals.navigator.clipboard.writeText(link);
    }
    eventBus.emit(EVENT_FORTNITE_ITEM_SHARE);
  }, [currentPath, metadata, t]);

  return (
    <Button
      type="button"
      onClick={copyLink}
      iconLeft={<ShareIcon width="12" />}
      emphasis="medium"
      data-tooltip={
        !globals.navigator?.share
          ? t("common:share.copied", "Web link copied to clipboard!")
          : undefined
      }
      data-event={!globals.navigator?.share ? "click" : undefined}
    >
      {t("common:share.share", "Share")}
    </Button>
  );
}

function ShopEntry() {
  const {
    parameters: [offerIdParam],
  } = useRoute();
  const {
    fortnite: { itemShop },
  } = useSnapshot(readState);
  const offerId = pathParamToOfferId(offerIdParam);
  useEffect(() => {
    setVolatileKV("fortnite-shop-offer-id", offerId);
  }, [offerId]);
  const entry = useMemo(() => {
    return itemShop?.entries?.find(
      IS_TESTING
        ? // first valid item, as items aren't guaranteed to be stable
          (entry) => entry.bundle?.name || entry.brItems?.[0].name || ""
        : // actual entry based on path
          (entry) => entry.offerId === offerId,
    );
  }, [itemShop, offerId]);
  const otherEntries = useMemo(() => {
    // TODO: may be show more relavent offers first
    return itemShop?.entries
      ?.filter((e) => e.offerId !== offerId)
      ?.slice(0, ENTRIES_LIMIT);
  }, [itemShop, offerId]);
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [variant, setVariant] = useState<Record<number, number>>({});
  const renderImage = entry?.newDisplayAsset?.renderImages?.[0];
  useEffect(() => {
    const [scroller] = globals.document.getElementsByClassName(APP_SCROLLER);
    scroller.scrollTo({ top: 0 });
  }, [selectedItem]);
  const videoSrc = useMemo(() => {
    const item = entry?.brItems?.[selectedItem];
    if (!item) return null;
    const shouldModify = Object.values(variant).some((v) => v !== 0);
    const id = shouldModify
      ? item.variants?.reduce((acc, _, vIndex) => {
          return `${acc}-${(variant[vIndex] || 0) + 1}`;
        }, "video")
      : "video";
    return item.videos?.find((v) => v.includes(id));
  }, [entry?.brItems, selectedItem, variant]);
  const entryName = entry?.bundle?.name || entry?.brItems?.[selectedItem]?.name;

  if (!entry) return null;

  return (
    <WikiLayout
      gameSymbol={GAME_SYMBOL_FORTNITE}
      title={
        [
          "fortnite:item.title",
          "{{title}}",
          { title: entryName },
        ] as Translation
      }
      imgSrc={renderImage?.image}
      containerClassName={WikiContainerCSS()}
      underTitle={
        <div className="flex row justify-between w-full">
          <Price
            price={entry.finalPrice}
            bannerValue={entry.banner?.value}
            intensity={entry.banner?.intensity}
          />
          <ShareButton />
        </div>
      }
      aboveHeader={<BackToShop />}
    >
      {videoSrc ? (
        <div className={classNames(ItemVideoCss(), "main").className}>
          <DraggableVideo src={videoSrc} />
        </div>
      ) : (
        <div
          className={
            classNames(
              ItemImageCss(
                entry?.colors?.color1 || "",
                entry?.colors?.color2 || "",
              ),
              "main",
            ).className
          }
        >
          <img
            src={
              entry.brItems?.[selectedItem].images.featured ||
              entry.brItems?.[selectedItem].images.icon
            }
            alt={entryName}
            loading="lazy"
          />
        </div>
      )}
      <div className="sidebar flex column gap-4">
        {entry.brItems?.length > 1 ? (
          <BundleInfoSidebar
            entry={entry as ItemShopEntry}
            selectItem={(index) => setSelectedItem(index)}
            selectedItem={selectedItem}
          />
        ) : null}
        <ItemVariantsSidebar
          item={entry.brItems?.[selectedItem] as Item}
          selectedVariant={variant}
          selectVariant={(vIndex, oIndex) =>
            setVariant({ ...variant, [vIndex]: oIndex })
          }
        />
        <ItemInfoSidebar item={entry.brItems?.[selectedItem] as Item} />
        <ItemActionsSidebar />
      </div>
      <Entries entries={otherEntries as ItemShopEntry[]} />
    </WikiLayout>
  );
}

export function meta([offerIdParam]: Array<string>): Meta {
  const offerId = pathParamToOfferId(offerIdParam);
  const entry = readState.fortnite.itemShop?.entries?.find(
    (e) => e.offerId === offerId,
  );
  const entryName = entry?.bundle?.name || entry?.brItems?.[0]?.name || "Item";
  const image = entry?.newDisplayAsset?.renderImages?.[0]?.image;
  return {
    title: [
      "fortnite:shop.entry.title",
      "{{ entryName }} - Fortnite item shop details, price history, rarity",
      { entryName },
    ],
    description: [
      "fortnite:shop.entry.desc",
      "Discover today's featured items in the Fortnite shop - don't miss out!",
    ],
    image: {
      url: image || hardCodeURLs.DEFAULT_OG_IMAGE,
      alt: ["fortnite:shop.entry.name", "{{ entryName }}", { entryName }],
      width: 1024,
      height: 1024,
    },
  };
}

export default ShopEntry;
